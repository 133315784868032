import { buildTimeConfig } from 'config'
import dayjs from 'dayjs'
import { useExportTransactionsSimpleFeedbackAlerts } from 'modules/bank/hooks/transaction/useExportTransactionsSimpleFeedbackAlerts'
import { EnumBankTrackingEvents } from 'modules/bank/lib/tracking/events'
import { useMonthTransactionsBlobLazyQuery } from 'modules/bank/queries/useMonthTransactionsBlobLazyQuery'
import { useTrackEvent } from 'modules/common/hooks/useTrackEvent'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { downloadFileFromBlob } from 'modules/common/lib/helpers/downloadFile/downloadFile'

interface Props {
  accountId: string
  monthDate: string | undefined
}

export const useExportMonthTransactionsSimpleFlow = ({
  accountId,
  monthDate,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const trackEvent = useTrackEvent()

  const {
    showExportTransactionsSuccessAlert,
    showExportTransactionsErrorAlert,
  } = useExportTransactionsSimpleFeedbackAlerts()

  const { fetchQuery: fetchMonthTransactionsBlob } =
    useMonthTransactionsBlobLazyQuery({
      accountId,
      monthDate,
      queryOptions: {
        onSuccess: (blob) => {
          if (blob) {
            downloadFileFromBlob({
              blob,
              fileName: t(
                'exportInternalAccountTransactionsModal.transactionsFileTitle',
                {
                  brand: buildTimeConfig.internalBankName.trim().toLowerCase(),
                  date: dayjs(monthDate).toDate(),
                }
              ),
            })
            trackEvent({
              eventType: EnumBankTrackingEvents.SIMPLE_TRANSACTIONS_EXPORT,
            })
            showExportTransactionsSuccessAlert()
          }
        },
        onError: showExportTransactionsErrorAlert,
      },
    })

  return {
    downloadMonthTransactions: fetchMonthTransactionsBlob,
  }
}
