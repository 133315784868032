import { AccountApi } from '@blank/api'
import { EnumExportTransactionsType } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { EnumBankTrackingEvents } from 'modules/bank/lib/tracking/events'
import { usePostAdvancedTransactionsExportMutation } from 'modules/bank/mutations/usePostAdvancedTransactionsExportMutation'
import { useFeedbackAlertCallbacks } from 'modules/common/hooks/useFeedbackAlertCallbacks'
import { useTrackEvent } from 'modules/common/hooks/useTrackEvent'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const useExportMonthTransactionsAdvancedFlow = (account: AccountApi) => {
  const { t } = useTranslation('bank-components')
  const trackEvent = useTrackEvent()

  const { showErrorFeedbackAlert, showSuccessFeedbackAlert } =
    useFeedbackAlertCallbacks({
      successDescription: t('exportTransactionsFeedback.success', {
        context: EnumExportTransactionsType.ADVANCED,
      }),
      errorDescription: t('exportTransactionsFeedback.error', {
        context: EnumExportTransactionsType.ADVANCED,
      }),
    })

  const { mutateAsync: buildAdvancedMonthTransactionExport } =
    usePostAdvancedTransactionsExportMutation({
      account,
      mutationOptions: {
        onMutate: () =>
          trackEvent({
            eventType: EnumBankTrackingEvents.ADVANCED_TRANSACTIONS_EXPORT,
            eventProperties: { source: account.source },
          }),
        onError: showErrorFeedbackAlert,
        onSuccess: showSuccessFeedbackAlert,
      },
    })

  return {
    buildAdvancedMonthTransactionExport,
  }
}
