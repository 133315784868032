import { FetchFileDownloadUrlResponseApi } from '@blank/api'
import { formatExportTransactionsDate } from 'modules/bank/utils/format/transactions'
import { apiClient } from 'modules/common/http/apiClient'
import { getBlobFromURL } from 'modules/common/lib/helpers/downloadFile/downloadFile'
import { EnumFileFormat } from 'modules/common/types/file'
import querystring from 'query-string'

interface Props {
  accountId: string
  monthDate: string
  accessToken: string
}

export const fetchMonthTransactionsBlob = async ({
  accountId,
  monthDate,
  accessToken,
}: Props) => {
  const apiUrl = querystring.stringifyUrl(
    {
      url: `/v1/bank/accounts/${accountId}/transactions/statement`,
      query: {
        dateFrom: formatExportTransactionsDate(monthDate),
        fileFormat: EnumFileFormat.PDF,
        signedUrl: true,
      },
    },
    { encode: false }
  )

  const { data } = await apiClient.get<FetchFileDownloadUrlResponseApi>(
    apiUrl,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  const blob = await getBlobFromURL(data.file.downloadSignedUrl)

  return blob
}
