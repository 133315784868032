import dayjs from 'dayjs'
import {
  EnumExportTransactionsType,
  ExportInternalAccountTransactionsFormData,
} from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { VerticalProgressStep } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { VerticalProgressStepper } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStepper'
import { IconEmail } from 'modules/common/components/_icons/icons'
import { RadioButton } from 'modules/common/components/_ui/FormControl/RadioButton/RadioButton'
import { TextField } from 'modules/common/components/_ui/FormControl/TextField/TextField'
import { HelperText } from 'modules/common/components/_ui/HelperText/HelperText'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useVerticalProgressStepStatus } from 'modules/common/hooks/useVerticalProgressStepStatus'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  TransactionMonthOption,
  TransactionMonthSelect,
} from '../TransactionMonthSelect/TransactionMonthSelect'

interface Props {
  monthOptions: TransactionMonthOption[]
  validatedStepNumber: number
}

export const ExportInternalAccountTransactionsFormContent = ({
  monthOptions,
  validatedStepNumber,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<ExportInternalAccountTransactionsFormData>()
  const selectedExportType = watch('exportType')
  const selectedMonth = watch('month')

  const isAdvancedExport =
    selectedExportType === EnumExportTransactionsType.ADVANCED

  const endMonthOptions = useMemo(
    () =>
      monthOptions.filter(
        (option) =>
          dayjs(option.value).isSame(selectedMonth?.value) ||
          dayjs(option.value).isAfter(selectedMonth?.value)
      ),
    [monthOptions, selectedMonth?.value]
  )

  const { registerStepStatus } =
    useVerticalProgressStepStatus(validatedStepNumber)

  return (
    <VerticalProgressStepper className="text-left">
      <VerticalProgressStep
        title={t('exportInternalAccountTransactionsForm.exportTypeTitle')}
        {...registerStepStatus(0)}
      >
        <RadioButton
          primaryLabel={t(
            'exportInternalAccountTransactionsForm.accountStatementLabel'
          )}
          id={EnumExportTransactionsType.SIMPLE}
          value={EnumExportTransactionsType.SIMPLE}
          className="mt-4"
          primaryLabelClassName="font-bold"
          {...register('exportType')}
        />
        <p className="mt-2">
          {t('exportInternalAccountTransactionsForm.accountStatementDesc')}
        </p>
        <RadioButton
          primaryLabel={t(
            'exportInternalAccountTransactionsForm.accountingExportLabel'
          )}
          id={EnumExportTransactionsType.ADVANCED}
          value={EnumExportTransactionsType.ADVANCED}
          className="mt-6"
          primaryLabelClassName="font-bold"
          {...register('exportType')}
        />
        <p className="mt-2">
          {t('exportInternalAccountTransactionsForm.accountingExportDesc')}
        </p>
      </VerticalProgressStep>
      <VerticalProgressStep
        title={t('exportInternalAccountTransactionsForm.monthTitle')}
        {...registerStepStatus(1)}
      >
        <div className="mb-3">
          <div className="flex gap-2">
            <div className="flex-1">
              <Controller
                name="month"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TransactionMonthSelect
                    onChange={(newValue) => newValue && onChange(newValue)}
                    onBlur={onBlur}
                    value={value}
                    options={monthOptions}
                    menuPlacement="top"
                    placeholderTitle={
                      isAdvancedExport
                        ? t('transactionMonthSelect.placeholderAdvancedFrom')
                        : t('transactionMonthSelect.placeholderSimple')
                    }
                  />
                )}
              />
            </div>
            {isAdvancedExport && (
              <div className="flex-1">
                <Controller
                  name="endMonth"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TransactionMonthSelect
                      onChange={(newValue) => newValue && onChange(newValue)}
                      onBlur={onBlur}
                      value={value}
                      options={endMonthOptions}
                      menuPlacement="top"
                      placeholderTitle={t(
                        'transactionMonthSelect.placeholderAdvancedTo'
                      )}
                      noOptionsMessage={() =>
                        t('transactionMonthSelect.noOptionMessage')
                      }
                    />
                  )}
                />
              </div>
            )}
          </div>
          {errors.endMonth?.value?.message && (
            <HelperText color="danger" text={errors.endMonth.value.message} />
          )}
        </div>
      </VerticalProgressStep>
      {isAdvancedExport && (
        <VerticalProgressStep
          title={t('exportInternalAccountTransactionsForm.email.title')}
          {...registerStepStatus(2)}
        >
          <TextField
            {...register('email')}
            label={t('exportInternalAccountTransactionsForm.email.label')}
            helperText={t(
              'exportInternalAccountTransactionsForm.email.helperText'
            )}
            leftDecorator={<IconEmail />}
            control={control}
            error={errors.email}
            isRequired
          />
        </VerticalProgressStep>
      )}
    </VerticalProgressStepper>
  )
}
